<template>
  <div>
    <template v-for="(dVal, dProp) in responseObject" :key="`${dProp}-g`">
      <template v-if="!keyIsExcluded(dProp)">
        <template v-if="responseObjectType === 'Array' || !numberToShow || dProp < numberToShow">

          <!--<b-form-group v-if="!isObject(dProp)"
                        :key="`${dProp}-g`"
                        :label="dProp | convertFromCamelCase">
            <input type="text" class="form-control" placeholder="Example input" v-model="responseObject[dProp]">
          </b-form-group>-->

          <p v-if="!isObject(dProp)"
               >
            <b>{{$filters.convertFromCamelCase(dProp)}}:</b><br />{{responseObject[dProp]}}
          </p>

          <t-card v-if="isObject(dProp)" class="my-3" :title="$filters.convertFromCamelCase(dProp)">
            <template #default>
              <data-nested :response-object="responseObject[dProp]"
                           :key-settings="keySettings"
                           :exclude-data-keys="excludeDataKeys" />
            </template>
          </t-card>

        </template>
      </template>
    </template>
  </div>
</template>

<script>

export default {
  name: 'DataNested',
  props: {
    responseObject: {},
    objectType: {},
    keySettings: {},
    numberToShow: {
      type: Number,
      default: 0
    },
    excludeDataKeys: {
      type: Array,
      default: () => { return [] }
    }
  },
  computed: {
    responseObjectType () {
      let type = 'unknown'

      if (typeof this.responseObject === 'object') {
        type = 'Object'
      } else if (Array.isArray(this.responseObject)) {
        type = 'Array'
      }

      return type
    }
  },
  methods: {
    isObject: function (prop) {
      return (typeof this.responseObject[prop] === 'object')
    },
    isArray: function (prop) {
      return (Array.isArray(this.responseObject[prop]))
    },
    keyIsExcluded: function (prop) {
      if (this.excludeDataKeys.length && this.excludeDataKeys.indexOf(prop) >= 0) {
        return true
      }

      if (this.keySettings && this.keySettings.excluded) {
        return (this.keySettings.excluded.indexOf(prop) > -1)
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
</style>
