<template>
  <div>
    <t-modal :id="modalName"
             :ref="modalName"
             :title="title"
             :size="size"
             @ok="saveData"
             @cancel="cancelChanges">
      <form>
        <div class="form-row">

        <template v-for="(dVal, dProp) in responseObject"  :key="dProp" >
          <template v-if="!keyIsExcluded(dProp)">
            <div v-if="!isObject(dProp) && isMutable(dProp)" class="form-group col-3">
              <label :for="'formGroupInput'+dProp">{{$filters.convertFromCamelCase(dProp)}}</label>
              <input :disabled="keyIsDisabled(dProp)" v-model="responseObject[dProp]" type="text" :id="'formGroupInput'+dProp" class="form-control" placeholder="Example input" />
            </div>

            <div v-if="isObject(dProp)" :key="dProp+'2'" class="col-12 mb-5">
              <t-collapse v-if="!keySettings[dProp] || !keySettings[dProp].type" plain>
                <template #header>
                  {{$filters.convertFromCamelCase(dProp)}}
                </template>
                <template #default>
                  <data-nested :response-object="responseObject[dProp]"
                               :key-settings="keySettings"
                               :object-type="isArray(responseObject[dProp])"
                               :number-to-show="1"
                               :exclude-data-keys="['customerGoalId', 'customerId']" />
                </template>
              </t-collapse>
              <t-collapse v-else plain>
                <template #header>Users</template>
                <data-list-view v-if="keySettings[dProp] && keySettings[dProp].type === 'data-list-view'"
                                :setter="keySettings[dProp].setter"
                                :key-settings="keySettings[dProp].keySettings"
                                :no-title="true"
                                :render-object="responseObject[dProp]" />
              </t-collapse>
            </div>
          </template>
        </template>

        </div>
      </form>
      <template #modal-ok>
        <span>Save</span>
      </template>
    </t-modal>
  </div>
</template>

<script>
import DataNested from '../../components/common/forms/dataNested'
import DataListView from '../../components/common/forms/dataListView'

export default {
  name: 'DataModelEdit',
  components: { DataListView, DataNested },
  // Some notes for props:
  // getter is the query string / path to get the data model
  // setter is the query string to set a data model
  // getQuery is the inital get query for the object
  // renderObject: if you want to pass an object directly in without using an API call
  // new tells us you want to do a create vs a view / edit
  props: [
    'getter',
    'setter',
    'getQuery',
    'new',
    'renderObject',
    'title',
    'modalName',
    'keySettings',
    'size'
  ],
  data () {
    return {
      query: '',
      responseObject: '',
      setQuery: '',
      showNewRow: false
    }
  },
  created () {
    this.init()

    this.$eventBus.$on('showDataModal', (modalName) => {
      if (modalName === this.modalName) {
        this.showModal()
      }
    })
  },
  watch: {
    getQuery: function (newVal, oldVal) { // watch it
      this.loadQuery()
      this.showModal()
    },
    renderObject: function (newVal, oldVal) { // watch it
      this.showModal()
      this.loadObject()
    }
  },
  methods: {
    init: function () {
      if (this.getter) {
        this.loadQuery()
      } else if (this.renderObject) {
        this.loadObject()
      }
    },
    isObject: function (prop) {
      return (typeof this.responseObject[prop] === 'object')
    },
    isMutable: function (prop) {
      switch (prop) {
        case 'accounts':
        case 'lastMonthSpend':
          return false
      }

      return true
    },
    loadQuery: async function () {
      if (!this.getter) {
        return
      }
      this.query = this.getter
      let getQuery = {}

      if (this.getQuery && this.getQuery !== '') {
        getQuery = this.getQuery
      }
      let response = false
      if (typeof this.getter === 'function') {
        response = await this.getter(getQuery)
      } else {
        response = await this.$res.fetch[this.getter](getQuery)
      }
      if (response) {
        this.$setCompat(this, 'responseObject', response)
      }
    },
    cancelChanges: function () {
      this.init()
    },
    saveData: async function () {
      // debugger
      let response = false
      if (typeof this.setter === 'function') {
        response = await this.setter(this.responseObject)
      } else {
        response = await this.$res.set[this.setter](this.responseObject)
      }

      if (response) {
        this.loadQuery()
        this.$emit('saved-data', response?.response?.requests?.[0])
        this.$toast('Data saved successfully!', 'success')
      }
    },
    loadObject: function () {
      this.responseObject = this.renderObject
    },
    showModal: function () {
      if (this.$refs[this.modalName]) {
        this.$refs[this.modalName].show()
      }
    },
    isArray: function (prop) {
      if (this.responseObject[prop]) {
        if (Array.isArray(this.responseObject[prop])) {
          return 'array'
        } else {
          return typeof this.responseObject[prop]
        }
      }
    },
    keyIsExcluded: function (prop) {
      if (this.keySettings && this.keySettings[prop]) {
        return (this.keySettings[prop].excluded)
      } else {
        return false
      }
    },
    keyIsDisabled: function (prop) {
      if (this.keySettings && this.keySettings[prop]) {
        return (this.keySettings[prop].disabled)
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
